import styled from "styled-components";

const Component = styled.div`
        padding: 20px 0 30px 10px;
    `
function AWLink({color="black"}) {
    return (
        <Component>
            <a href={'https://artwrld.com'}>
            <svg width="33" height="16" viewBox="0 0 33 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_57_1154)">
                    <path d="M10.1907 1.54737C9.22085 1.11276 8.16314 0.90025 7.09744 0.925903C6.09398 0.911692 5.09819 1.0996 4.17154 1.47803C3.33548 1.82461 2.61204 2.38947 2.07974 3.11132C1.53469 3.86726 1.21094 4.75543 1.14367 5.67937H4.15847C4.22204 5.0465 4.53786 4.464 5.03702 4.05893C5.53034 3.66345 6.20843 3.46571 7.07129 3.46571C7.8095 3.42613 8.53717 3.65158 9.11864 4.10002C9.35774 4.29023 9.54992 4.53111 9.68079 4.80463C9.81165 5.07814 9.8778 5.37718 9.8743 5.67937C9.89067 5.95101 9.79663 6.21793 9.61282 6.42154C9.33991 6.63225 9.00972 6.75911 8.66367 6.7862L5.88943 7.21507C4.25783 7.46673 3.0167 7.94354 2.16603 8.64547C1.31537 9.34741 0.889164 10.289 0.887421 11.4703C0.873946 12.1795 1.05455 12.8793 1.41037 13.4965C1.76694 14.0979 2.29757 14.5815 2.93476 14.8858C3.70348 15.2401 4.54656 15.4108 5.39524 15.384C6.32665 15.3895 7.24085 15.1376 8.03352 14.6573C8.82585 14.1772 9.46113 13.4837 9.86384 12.6593V15.0656H12.8969V5.86427C12.9138 4.96057 12.6709 4.07051 12.1962 3.29622C11.7152 2.534 11.0182 1.92621 10.1907 1.54737ZM9.8743 8.83294C9.88566 9.57276 9.73029 10.3059 9.41933 10.9798C9.14573 11.5868 8.70349 12.1057 8.14334 12.477C7.58008 12.8394 6.91823 13.0256 6.24503 13.0112C5.63752 13.0443 5.0381 12.862 4.55591 12.4976C4.35128 12.3132 4.19013 12.0871 4.08375 11.8352C3.97736 11.5833 3.92831 11.3116 3.94001 11.0391C3.95172 10.7666 4.0239 10.4999 4.15152 10.2577C4.27913 10.0154 4.45909 9.80352 4.6788 9.63674C5.30084 9.2682 5.99317 9.02873 6.71307 8.93309L8.60876 8.64547C8.86401 8.61395 9.11656 8.56417 9.36442 8.49652C9.54392 8.45482 9.71586 8.3864 9.8743 8.29365V8.83294Z" fill={color}/>
                    <path d="M29.6318 11.1879L29.6188 11.1263L24.5645 1.35742H21.9026L21.0241 11.1545L16.0456 1.35742H12.8975L20.1795 15.0683H23.2466L24.1252 5.67432L29.0174 15.0683H29.6318H32.0374H32.5368V1.35742H29.6318V11.1879Z" fill={color}/>
                </g>
                <defs>
                    <clipPath id="clip0_57_1154">
                        <rect width="33" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
            </a>

        </Component>
    )
}

export default AWLink;