import Gallery from "./Gallery";
import Work from "./Work";
import React from "react";

const BundleGallery = () => {
    return (
        <Gallery>
            <Work name={'Classic'}
                  video={require('../videos/Classic.mp4')}
                  xvimeo={'https://player.vimeo.com/progressive_redirect/playback/880958865/rendition/1080p/file.mp4?loc=external&log_user=0&signature=f30f0957213054a76ab4a878aa62432125721ef04221d0ce796b003a162cc197'}
                  art={'/images/Classic.jpg'}></Work>
            <Work name={'The Chelsea'}
                  video={require('../videos/Chelsea.mp4')}
                  xvimeo={'https://player.vimeo.com/progressive_redirect/playback/880958919/rendition/1080p/file.mp4?loc=external&log_user=0&signature=63bdefe655dddb462e6421329e7d356ac318671315147b4cd3e0073af4d665e3'}
                  art={'/images/Chelsea.jpg'}></Work>
            <Work name={'The Village'}
                  video={require('../videos/Village.mp4')}
                  xvimeo={'https://player.vimeo.com/progressive_redirect/playback/880958949/rendition/720p/file.mp4?loc=external&log_user=0&signature=c5ce24a134374b6f1ba97b79e08d50d12b31aa382ec14fed04adf2857624b5d6'}
                  art={'/images/The_Village.jpg'}></Work>
            <Work name={'Elevators'}
                  video={require('../videos/Elevators.mp4')}
                  xvimeo={'https://player.vimeo.com/progressive_redirect/playback/880958896/rendition/1080p/file.mp4?loc=external&log_user=0&signature=4722bd4be7d85eb8fdd34d10e6aa1035be1d5fa4178406e9ca2b784ec6230c54'}
                  art={'/images/Elevators.jpg'}></Work>
            <Work name={'Traffic'}
                  video={require('../videos/Traffic.mp4')}
                  xvimeo={'https://player.vimeo.com/progressive_redirect/playback/880958986/rendition/1080p/file.mp4?loc=external&log_user=0&signature=53f07030fc78ff968357ffba54dbfbe619aa50778b0bbc66a3158467b1d60e46'}
                  art={'/images/Traffic.jpg'}></Work>
            <Work name={'Buildings'}
                  video={require('../videos/Buildings.mp4')}
                  xvimeo={'https://player.vimeo.com/progressive_redirect/playback/880958793/rendition/1080p/file.mp4?loc=external&log_user=0&signature=fb9f9bdc8e6dbb8677f4edc765bd99285862f089331831b3940ccd42ec1951a7'}
                  art={'/images/Buildings.jpg'}></Work>
        </Gallery>
    )
}

export default BundleGallery;