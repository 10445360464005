import styled from "styled-components";
import {useEffect, useRef, useState} from "react";
const Component = styled.div`
  min-width: 30%;
  max-width: 200px;
  display: flex;
  flex-direction: column;

  @media screen and (max-height: 800px) {
    img, video {
      height: 300px;
      object-fit: contain;
      object-position: center left;
    }
  }
  @media screen and (max-width: 650px) {
    max-width: unset;
    min-width: unset;
    align-items: center;
    img, video {
      height: 300px;
    }
  }
  .placeholder {
    min-width: 30%;
    max-width: 200px;
    transition: background-color .2s ease;
    height: 300px;
    width: auto;
    aspect-ratio: 1/1.3;
    display: block;
    video {
      width: 100%;
      height: 100%;
      transition: opacity .5s ease;
      opacity: 0;
      &.playing {
        opacity: 1;
      }
    }
  }
`
function Work({name, art, single, video=null, vimeo=null}) {
    const frame = useRef();
    const vid = useRef();
    const [playing, setPlaying] = useState(false);
    useEffect(() => {
        vid.current.play();
        vid.current.addEventListener('play', ()=>{setTimeout(() => setPlaying(true), 1000)})
    }, [vid])
    if(vimeo) {
        return (
            <Component style={single ? {width: 1000, maxWidth: 300} : {}}>
                <iframe
                    ref={frame}
                    width="100%"
                    height="100%"
                    src={vimeo}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope;"
                    style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}>
                </iframe>
                <span>{name}</span>
            </Component>
        )
    }
    if(video) {
        return (
            <Component style={single ? {width: 1000, maxWidth: 300} : {}}>
                <div className={'placeholder'} style={{background: playing ? 'transparent': 'rgba(0,0,0,0.1)'}}>
                    <video className={playing ? 'playing' : ''} ref={vid} playsInline={true} loop={true} muted={true} controls={false} autoPlay={true}>
                        <source src={video} type='video/mp4'/>
                        <source src={video} type='video/mp4'/>
                    </video>
                </div>
                <span>{name}</span>
            </Component>
        )
    }
    return (
        <Component style={single ? {width: 1000, maxWidth: 300} : {}}>
            <img alt={name} src={art}/>
            <span>{name}</span>
        </Component>
    )
}

export default Work;