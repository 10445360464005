import styled from "styled-components";

const Component = styled.div`
      background: #E3DBD4;
      width: 100%;
      display: grid;
      grid-template-rows: 1fr 40px;
      height: 100vh;

  @media screen and (max-width: 650px) {
    display: none; 
  }
      .top {
        overflow: auto;
        display: flex;
        height: 100%;
        align-items: safe center;
      }
      .bottom {
        
      }
  &.success {
   background: #408040;
    padding-top: 90px;
    height: calc(100vh - 90px);
    min-height: -webkit-fill-available;
  }
    `
function Right({children, className=''}) {
    return (
        <Component className={className}>
            <div className={'top'}>
                {children[0]}
            </div>
            <div className={'bottom'}>
                {children[1]}
            </div>

        </Component>
    )
}

export default Right;