import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import AWLink from "./components/AWLink";
import Right from "./components/Right";
import Left from "./components/Left";
import SuccessContainer from "./components/SuccessContainer";
import styled from "styled-components";
import Title from "./components/Title";
import React from "react";
import BundleGallery from "./components/BundleGallery";

const Component = styled.div`
  color: white;
  max-height: 100vh;
  overflow: auto;

  .centered {
    display: flex;
    align-items: start;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }

  .back_container {
    position: absolute;
    top: 10px;
    right: 30px;
    @media screen and (max-width: 650px) {
      top: 20px;
    }

    .back {
      @media screen and (max-width: 650px) {
        display: block;
      }
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }
  .mobile_gallery {
    background: #317231;
    display: none;
    margin: 30px -20px;
    @media screen and (max-width: 650px) {
      display: block;
    }
  }
  a.opensea {
    cursor: pointer;
    border: none;
    border-radius: 7px;
    font-size: 0.7em;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 130px;
    text-decoration: none;
    padding: 10px;
    background: black;
    color: #EEE;
    svg {
      margin-right: 10px;
    }
  }
`

function SuccessBundle() {
    const location = useLocation();
    const navigate = useNavigate()
    const back = () => {
        navigate('/');
    }
    const [searchParams, _] = useSearchParams();
    const to = searchParams.get('to');
    const token_ids = searchParams.getAll('token_id');
    const currency = searchParams.get('currency');
    const count = token_ids.length;

    const opensea_link = (i) => currency === 'goerli' ?
        `https://testnets.opensea.io/assets/goerli/${to}/${token_ids[i]}` :
        `https://opensea.io/assets/ethereum/${to}/${token_ids[i]}`;

    return (
        <Component>
            <div className={'back_container'}>
                <button className={'back'} onClick={() => back()}>
                    <svg width="49" height="73" viewBox="0 0 49 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.499998 24.5C0.499999 11.2452 11.2452 0.500001 24.5 0.500002C37.7548 0.500003 48.5 11.2452 48.5 24.5C48.5 37.7548 37.7548 48.5 24.5 48.5C11.2452 48.5 0.499997 37.7548 0.499998 24.5Z" stroke="white" strokeOpacity="0.2"/>
                        <path d="M24.5 14.8014L14.8013 24.5L24.4999 34.1986" stroke="white" strokeWidth="2"/>
                        <path d="M14.8013 24.5001L34.1986 24.5001" stroke="white" strokeWidth="2"/>
                        <path d="M8.33641 61.188H10.6284C11.5524 61.188 12.2724 60.576 12.2724 59.58C12.2724 58.668 11.6844 58.044 10.6404 58.044H8.33641V61.188ZM7.26841 66V57.12H10.7124C12.3444 57.12 13.3644 58.02 13.3644 59.544C13.3644 60.888 12.4524 61.836 11.1084 62.004C11.4324 62.208 11.7444 62.64 12.0324 63.012L14.3004 66H12.9924L10.9644 63.3C10.0884 62.136 10.0524 62.112 9.09241 62.112H8.33641V66H7.26841ZM19.5647 63.9H20.5367C20.4167 65.244 19.3847 66.144 17.8247 66.144C15.7847 66.144 14.8727 64.8 14.8727 62.808C14.8727 60.852 15.8447 59.484 17.7887 59.484C19.7327 59.484 20.5607 60.756 20.5607 62.58C20.5607 62.616 20.5607 62.88 20.5607 62.916H15.8567C15.8807 64.524 16.6247 65.316 17.7527 65.316C18.7127 65.316 19.4327 64.872 19.5647 63.9ZM15.8807 62.184H19.5767C19.5407 61.044 18.9047 60.288 17.8127 60.288C16.7327 60.288 16.0367 60.9 15.8807 62.184ZM22.4137 64.344V60.408H21.4897V59.58H22.4137V57.924H23.3737V59.58H24.8737V60.408H23.3737V64.26C23.3737 64.944 23.7097 65.232 24.2737 65.232C24.4657 65.232 24.6577 65.208 24.8977 65.172V66.024C24.6457 66.084 24.3937 66.12 24.1057 66.12C23.1097 66.12 22.4137 65.592 22.4137 64.344ZM25.9977 63.78V59.58H26.9577V63.516C26.9577 64.68 27.3177 65.268 28.2777 65.268C29.3817 65.268 30.1017 64.512 30.1017 63.312V59.58H31.0617V66H30.1017V64.968H30.0777C29.6457 65.664 29.0457 66.12 28.0377 66.12C26.5617 66.12 25.9977 65.268 25.9977 63.78ZM33.2019 66V59.58H34.1619V60.564H34.1859C34.5939 59.88 35.1459 59.46 36.0579 59.46C36.0939 59.46 36.3099 59.46 36.3459 59.472V60.48C36.2139 60.444 36.0699 60.432 35.9259 60.432C34.8579 60.432 34.1619 61.128 34.1619 62.34V66H33.2019ZM37.5702 66V59.58H38.5302V60.564H38.5542C38.9862 59.892 39.5982 59.46 40.6542 59.46C42.1902 59.46 42.7542 60.312 42.7542 61.8V66H41.7942V62.064C41.7942 60.9 41.4222 60.312 40.4142 60.312C39.2622 60.312 38.5302 61.068 38.5302 62.268V66H37.5702Z" fill="white"/>
                    </svg>
                </button>
            </div>
            <SuccessContainer style={{background: '#408040', height: '100%', overflow: 'auto', marginTop: -50, paddingTop: 30, paddingBottom:25}}>
                <Left className={'success'}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <AWLink color={'white'}></AWLink>
                    </div>
                    <div className={'centered'}>
                        <Title>
                            Thank you for acquiring {count === 1 ? 'a bundle' : `${count} bundles`} of artworks from John F. Simon Jr.'s
                            NFT collection, <i>ComplexCity</i>.
                        </Title>
                        <p style={{marginTop: 100, maxWidth: 400, fontWeight: 300, opacity: .7}} >
                            Please follow us on social to stay up to date about upcoming
                            programing related to the collection.
                        </p>
                    </div>
                    <div className={'mobile_gallery'}>
                        <BundleGallery/>
                    </div>
                    {token_ids.length === 1 ? (
                        <div>
                            <a className={'opensea'} href={opensea_link(0)} target={'_blank'} rel="noreferrer">View on OpenSea</a>
                        </div>
                    ) : (
                        <div style={{display: 'flex', flexDirection: 'row', gap: 10}}>
                            {token_ids.map((id, i) => {
                                return (<a style={{width: 'min-content', whiteSpace: 'nowrap'}} className={'opensea'} href={opensea_link(i)} target={'_blank'} rel="noreferrer">View Token {i + 1} on OpenSea</a>)
                            })}
                        </div>
                    )}
                </Left>
                <Right className={'success'}>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <div style={{background: '#317231', padding: 20}}>
                            <BundleGallery/>
                        </div>
                    </div>
                    <div></div>
                </Right>
            </SuccessContainer>
        </Component>
    )
}

export default SuccessBundle;