import styled from "styled-components";

const Component = styled.div`
      flex-grow: 0;
      padding: 10px;
      font-size: 0.75em;
      line-height: 1.2em;
      margin-left: 10px;
    `
function Disclaimer({children}) {
    return (
        <Component>
            {children}
        </Component>
    )
}

export default Disclaimer;