import styled from "styled-components";

const Component = styled.div`
      margin-top: 10px;
        display: flex;
      flex-direction: row;
      .title {
        font-weight: 300;
        font-size: .75em;
        margin-bottom: 3px;
      }
      .value {
        font-size: 1.25em;
      }
      .divider {
        border-left: 1px solid #8C8C8C;
        margin-left: 30px;
        margin-right: 30px;
      }
    `
function Info({price, count, collection_type='set'}) {
    return (
        <Component>
            <div>
                <div className={'title'}>Price:</div>
                <div className={'value'}>
                    <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.29514 0.5L5.18848 0.866667V11.4467L5.29514 11.5533L10.2018 8.64667L5.29514 0.5Z" fill="#8C8C8C"/>
                    <path d="M5.29517 0.5L0.381836 8.64667L5.29517 11.5533V6.41333V0.5Z" fill="#E1E1E1"/>
                    <path d="M5.29511 12.48L5.23511 12.5533V16.32L5.29511 16.5L10.2084 9.57996L5.29511 12.48Z" fill="#8C8C8C"/>
                    <path d="M5.29517 16.5V12.48L0.381836 9.57996L5.29517 16.5Z" fill="#E1E1E1"/>
                    <path d="M5.29517 11.5533L10.2018 8.64666L5.29517 6.41333V11.5533Z" fill="#393939"/>
                    <path d="M0.381836 8.64666L5.29517 11.5533V6.41333L0.381836 8.64666Z" fill="#838383"/>
                </svg>
                    {price} ETH
                </div>
            </div>
            <div className={'divider'}></div>
            <div style={{flexGrow: 1}}>
                <div className={'title'}>Artworks in the {collection_type}:</div>
                <div className={'value'}>{count}</div>
            </div>
        </Component>
    )
}

export default Info;