import styled from 'styled-components';

const Component = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  padding: 10px 50px;
  justify-content: safe center;
  max-width: 900px;
  @media screen and (max-width: 650px) {
    
  }
`

function Gallery({children}) {
    return <Component className='gallery'>
        {children}
    </Component>
}

export default Gallery;