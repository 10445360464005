import styled from "styled-components";

const Component = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  @media screen and (max-width: 650px) {
    grid-template-columns: 1fr;
    min-height: 100vh;
  }
`
function SuccessContainer({children}) {
    return (
        <Component>
                {children[0]}
                {children[1]}
        </Component>
    )
}

export default SuccessContainer;