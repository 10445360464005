
import './App.css';
import '@rainbow-me/rainbowkit/styles.css';
import AWLink from './components/AWLink'
import Title from './components/Title'
import Info from './components/Info'
import ScrollPane from './components/ScrollPane'
import DetailsPane from './components/DetailsPane'
import Purchase from './components/Purchase'
import Disclaimer from './components/Disclaimer'
import Right from './components/Right'
import Left from './components/Left'
import Container from './components/Container'
import React, {useEffect, useState} from "react";
import {createAlchemyWeb3} from "@alch/alchemy-web3";
import {useParams} from "react-router-dom";
import Pending from "./components/Pending";
import BundleGallery from "./components/BundleGallery";
import {coinbaseWallet, localWallet, metamaskWallet, ThirdwebProvider, walletConnect} from "@thirdweb-dev/react";


import {
    getDefaultWallets,
    RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import {
    mainnet,
    goerli
} from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';



const api_key = "_paRw1xBx0d8rT1WHBiaTRsjj8e_ZE3D"; // TODO Not this
const web3x = createAlchemyWeb3(`wss://eth-goerli.g.alchemy.com/v2/${api_key}`);


const App = ({contract_address, abi, nfts, nft_abi, currency, price}) => {

    const { chains, publicClient } = configureChains(
        [mainnet, goerli],
        [
            alchemyProvider({ apiKey: '_paRw1xBx0d8rT1WHBiaTRsjj8e_ZE3D' }),
            publicProvider()
        ]
    );
    const { connectors } = getDefaultWallets({
        appName: 'Artwrld2',
        projectId: '0816a04e672c6d4a5b4a26630f114d31',
        chains
    });

    const wagmiConfig = createConfig({
        autoConnect: true,
        connectors,
        publicClient
    })

    return (
        <WagmiConfig config={wagmiConfig}>
            <RainbowKitProvider chains={chains}>
            <John {...{contract_address, abi, nfts, nft_abi, currency, price}}/>
            </RainbowKitProvider>
        </WagmiConfig>
    )
}

const John = ({contract_address, abi, nfts, nft_abi, currency, price}) => {

    const {id} = useParams();
    const [contract, setContract] = useState(null)
    const [nftContract, setNftContract] = useState(null)
    const [realPrice, setRealPrice] = useState(web3x.utils.toWei(price, 'ether'));

    useEffect(() => {
        const new_contract = new web3x.eth.Contract(abi, contract_address);
        const nft_contract = new web3x.eth.Contract(nft_abi, nfts);
        setContract(new_contract);
        setNftContract(nft_contract);
        const get_price = async (new_contract) => {
            if(!new_contract) { return; }
            const price = await new_contract.methods.getPriceInfo(id).call()
            setRealPrice(price.tokenPriceInWei)
        }
        get_price(new_contract);
    }, [abi, id, contract_address])


    const [pending, setPending] = useState(false);

    return (
        <>
            <Pending show={pending} setPending={setPending}/>
            <Container id="container">
                <Left>
                    <AWLink></AWLink>
                    <Title>John F. Simon Jr.</Title>
                    <Title>ComplexCity</Title>
                    <Title subtitle={true}>In Partnership with Art Blocks Engine</Title>
                    <div className={'mobile_gallery'}>
                        <BundleGallery/>
                        <Disclaimer>
                            *Artwork previews representative of the six different templates used throughout the collection.
                        </Disclaimer>
                    </div>
                    <Info price={web3x.utils.fromWei(realPrice, 'ether')} collection_type='collection' count={512}></Info>
                    <ScrollPane>
                        <DetailsPane type={'Release Phases'}>
                           <div style={{display: "flex", flexDirection: 'column'}}>
                               <div style={{marginTop: 20, fontWeight: 'bold'}}>
                                   November 9th, 2023
                               </div>
                               <div>
                               <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20, marginTop: 10, borderTop: '1px solid black', paddingTop: 15}}>
                                   <div>12 - 1 PM ET</div>
                                   <div style={{lineHeight: 1.6, textAlign: 'end', fontWeight: 'bold', fontSize: '0.8em'}}>Artwrld Collector Mint</div>
                               </div>
                               <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20, marginTop: 10, borderTop: '1px solid black', paddingTop: 15}}>
                                   <div>1PM ET</div>
                                   <div style={{lineHeight: 1.6, textAlign: 'end', fontWeight: 'bold', fontSize: '0.8em'}}>Public Sale</div>
                               </div>

                               </div>
                           </div>
                        </DetailsPane>
                        <DetailsPane type={'Description'}>
                            John F. Simon Jr.’s <i>ComplexCity</i> (2000/2023) is a collection of 512 unique generative artworks updated from Simon’s seminal software artwork of the same name. The works in the collection are constructed from six possible templates and vary in composition, interactivity, and rarity.
                            <br/>
                            <br/>
                            Similar to his earlier physical pieces, these artworks are not videos but software that is constantly changing — real-time animations that create endless combinations in continuous motion.
                            <br/>
                            <br/>
                            Please note that these works are minted at random.
                        </DetailsPane>
                    </ScrollPane>
                    <div style={{marginTop: 60}}></div>
                    <Purchase {...{web3x, contract, currency, price, setPending, nftContract, show_count:false}}></Purchase>
                    <div style={{marginTop: 20}}></div>
                </Left>
                <Right>
                    <BundleGallery/>
                    <Disclaimer>
                        *Artwork previews representative of the six different templates used throughout the collection.
                    </Disclaimer>
                </Right>
            </Container>
        </>
    );
}

export default App;
