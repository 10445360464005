import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {createSearchParams, useNavigate} from "react-router-dom";

import {
    ConnectWallet,
    useConnectedWallet,
    useContract
} from "@thirdweb-dev/react";

const Component = styled.div`
  .error {
    background: #e45151;
    color: white;
    padding: 5px;
    border-radius: 7px;
    border: 2px solid #a70c0c;
  }
  .quantity_section {
    display: flex;
    justify-content: space-between;
    .quantity_select {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .count {
      margin-right: 10px;
    }
    .buttons {
      display: flex;
      flex-direction: column;
    }
    button {
      background: transparent;
      border: none;
    }
  }
  .pay_button, .connect_button {
    width: 100%;
    margin-top: 10px;
  }
  .pay_button {
    display: flex;
    flex-direction: row;
    height: 50px;
    gap: 10px;
  }
  .connect_button button > div, .pay_button button {
      cursor: pointer;
      padding: 0;
      border: none;
      border-radius: 7px;
      font-size: 0.7em;
      align-items: center;
      justify-content: center;
      display: flex;
      width: 100%;
      background: black;
      color: #EEE;
        svg {
          margin-left: 10px;
        }
    }
  
`;


function Purchase({contract_address, currency, setPending}) {

    const { contract } = useContract(contract_address);

    const [count, setCount] = useState(1);
    const [error, setError] = useState(false);
    const wallet_add = useConnectedWallet()?._address;
    const navigate = useNavigate();


    const claim_nft = async () => {
        let tx;
        setPending(true);
        setError(false);
        try {
            tx = await contract.erc721.claim(count);
            for(const result of tx) {
                result._data = await result.data()
            }
            console.log(tx, createSearchParams());
            navigate({pathname: '/success_bundle', search: createSearchParams({
                    currency,
                    to: tx[0].receipt.to,
                    token_id: tx.map(t => t._data.metadata.id)
            }).toString()});
        } catch (e) {
            setPending(false);
            setError('Error Processing Purchase')
            console.log(e);
            console.log({reason: e?.info?.reason, code: e?.info?.code, e});
        }
    }

    return (
        <Component>
            <div className={'quantity_section'}>
                <div className={'quantity'}>Quantity</div>
                <div className={'quantity_select'}>
                    <div className={'count'}>{count}</div>
                    <div className={'buttons'}>
                        <button onClick={() => setCount(e => e+1)}>
                            <svg height="10" width="16">
                                <polygon points="8,0 0,10 15,10" style={{fill: '#8C8C8C'}} />
                            </svg>
                        </button>
                        <button onClick={() => setCount(e => Math.max(1, e-1))}>
                            <svg height="10" width="16">
                                <polygon points="8,10 0,0 15,0" style={{fill: count === 1 ? '#00000020' : '#8C8C8C'}} />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            {error && (
                <div className={'error'}>{error}</div>
            )}
            {wallet_add ? (
                <div className={"pay_button"}>
                    <ConnectWallet style={{width: 200}}/>
                    <button onClick={() => claim_nft()}>
                        Pay with Crypto Wallet
                        <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.29514 0.5L5.18848 0.866667V11.4467L5.29514 11.5533L10.2018 8.64667L5.29514 0.5Z" fill="#8C8C8C"/>
                            <path d="M5.29517 0.5L0.381836 8.64667L5.29517 11.5533V6.41333V0.5Z" fill="#E1E1E1"/>
                            <path d="M5.29511 12.48L5.23511 12.5533V16.32L5.29511 16.5L10.2084 9.57996L5.29511 12.48Z" fill="#8C8C8C"/>
                            <path d="M5.29517 16.5V12.48L0.381836 9.57996L5.29517 16.5Z" fill="#E1E1E1"/>
                            <path d="M5.29517 11.5533L10.2018 8.64666L5.29517 6.41333V11.5533Z" fill="#393939"/>
                            <path d="M0.381836 8.64666L5.29517 11.5533V6.41333L0.381836 8.64666Z" fill="#838383"/>
                        </svg>
                    </button>
                </div>
            ) : (
                <div className={"connect_button"}>
                    <ConnectWallet btnTitle={(
                        <div>Connect Wallet
                            <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.29514 0.5L5.18848 0.866667V11.4467L5.29514 11.5533L10.2018 8.64667L5.29514 0.5Z" fill="#8C8C8C"/>
                            <path d="M5.29517 0.5L0.381836 8.64667L5.29517 11.5533V6.41333V0.5Z" fill="#E1E1E1"/>
                            <path d="M5.29511 12.48L5.23511 12.5533V16.32L5.29511 16.5L10.2084 9.57996L5.29511 12.48Z" fill="#8C8C8C"/>
                            <path d="M5.29517 16.5V12.48L0.381836 9.57996L5.29517 16.5Z" fill="#E1E1E1"/>
                            <path d="M5.29517 11.5533L10.2018 8.64666L5.29517 6.41333V11.5533Z" fill="#393939"/>
                            <path d="M0.381836 8.64666L5.29517 11.5533V6.41333L0.381836 8.64666Z" fill="#838383"/>
                             </svg>
                        </div>
                    )} style={{color: 'white', fontFamily:'synthese', background: 'black', width: '100%'}}/>
                </div>
            )}

        </Component>
    )
}

export default Purchase;