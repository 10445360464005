import styled from "styled-components";
import {useCollapse} from 'react-collapsed';

const Component = styled.div`
      .collapser {
        flex-grow: 1;
        min-height: 0;
        
      display: grid;
      grid-template-columns: 150px auto 30px;
        grid-template-areas: "title content toggle";
      padding-bottom: 10px;
      padding-top: 20px;
      border-top: 1px solid #8C8C8C;
      margin-top: 20px;

        @media screen and (max-width: 1400px) {
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 40px 1fr;
          grid-template-areas: "title toggle"
                             "content content";
        }
      }
      .title { 
        grid-area: title;
      }
      .content {
        grid-area: content;
        @media screen and (max-width: 650px) {
          padding: 15px;
          background: #E3DBD4;
        }
      }
      .toggle {
        grid-area: toggle;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
      }
      button {
        background: transparent;
        border: none;
      }
    `
function DetailsPane({children, type}) {

    return (
        <Component>
            <DetailsPane2 children={children} type={type}></DetailsPane2>
        </Component>
    )
}
function DetailsPane2({children, type}) {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

    return (
        <div className={'collapser'} >
            <div className={'title'}  {...getToggleProps()} style={{cursor: "pointer"}}>{type}</div>
            <section className={'content'} {...getCollapseProps()}>{children}</section>
            {!isExpanded && <section style={{cursor: 'pointer'}}  {...getToggleProps()}></section>}
            <div className={'toggle'} >
                <button {...getToggleProps()} style={{cursor: "pointer"}}>
                {isExpanded ? (
                    <svg  style={{transform: "rotate(180deg)"}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.35718 7.5L10.3572 12.5L15.3572 7.5" stroke="#8C8C8C"/>
                    </svg>
                ) : (   <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.35718 7.5L10.3572 12.5L15.3572 7.5" stroke="#8C8C8C"/>
                </svg>)}
                </button>
            </div>
        </div>
    )
}

export default DetailsPane;