import styled from "styled-components";
const Component = styled.div`
        flex-grow: 1;
    `
function ScrollPane({children}) {

    return (
        <Component>
            {children}
        </Component>
    )
}

export default ScrollPane;