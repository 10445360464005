import styled from "styled-components";
const Component = styled.div`
      h1 {
        font-size: 3em;
        font-weight: 300;
        font-family: synthese, sans-serif;
      }
      h2 {
        font-size: .9em;
      }



      @media screen and (max-width: 1375px) {
        h1 {
          font-size: 2.5em;
        }
        h2 {
          font-size: .8em;
        }
      }
      @media screen and (max-width: 1275px) {
        h1 {
          font-size: 2em;
        }
        h2 {
          font-size: .7em;
        }
      }
      @media screen and (max-width: 945px) {
        h1 {
          font-size: 1.5em;
        }
        h2 {
          font-size: .7em;
        }
      }
      @media screen and (max-width: 650px) {
        h1 {
          font-size: 2em;
        }
        h2 {
          font-size: 1em;
          margin-bottom: 25px;
        }
      }
      h1, h2 {
        margin: 0;
        padding: 0;
      }
    `
function Title({children, subtitle=false}) {

    return (
        <Component>
            {subtitle ? <h2>{children}</h2>:<h1>{children}</h1>}
        </Component>
    )
}
export default Title;