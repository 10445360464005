import styled from "styled-components";

const Component = styled.div`
        padding: 20px;
      display: flex;
      flex-direction: column;
      height: calc(100% - 40px);
      overflow: auto;

  &.success {
    background: #408040;
  }
    `
function Left({children, className=''}) {
    return (
        <Component className={className}>
            {children}
        </Component>
    )
}

export default Left;