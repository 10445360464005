import styled from "styled-components";

const Component = styled.div`
        position: absolute;
        inset: 0;
        display: flex;
  align-items: center;
  justify-content: center;
  animation-name: fade;
  animation-duration: .25s;
  animation-iteration-count: 1;
  background: rgba(0,0,0,0.4);
  .pending {
    animation-name: show;
    animation-duration: .25s;
    animation-iteration-count: 1;
  }
  .loader {
    display: block;
    height: 28px;
    width: 250px;
    position: relative;
    background: #333;
  }
  .bar {
    position: absolute;
    background: white;
    border-radius: 2px;

    background: linear-gradient(90deg, white 0%, white 75%, rgba(255,255, 255,0.55) 100%);
    animation-name: chain;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    height: 3px;
    top: 12px;
    left: 25px;
    right: 15px;
  }
  .block {
    z-index: 5;
    border-radius: 1px;
    position: absolute;
    top: 6px;
    height: 15px;
    width: 15px;
    background-color: white;
    animation-name: block;
    animation-duration: 7.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
  .block:nth-of-type(2) {
    animation-delay: -1.5s;
  }
  .block:nth-of-type(3) {
    animation-delay: -3s;
  }
  .block:nth-of-type(4) {
    animation-delay: -4.5s;
  }
  .block:nth-of-type(5) {
    animation-delay: -6s;
  }
  @keyframes show {
    0% {
      opacity: 0;
      transform: translateY(5px);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }
  @keyframes fade {
    0% {
      background: rgba(0,0,0,0);
    }
    100% {
      background: rgba(0,0,0,0.4);
    }
  }
  @keyframes block {
    0%{
      background: rgba(255,255,255,0);
      left: 220px;
      transform: scale(0%);
    }
    8%{
      background: rgba(255,255,255,0);
      left: 220px;
      transform: scale(0%);
    }
    10%{
      background: rgba(255,255,255,1);
      left: 220px;
      transform: scale(120%);
    }
    12%{
      background: rgba(255,255,255,1);
      left: 220px;
      transform: scale(100%);
    }
    90% {
      left: 20px;
      transform: scale(100%);
    }
    100% {
      left: 20px;
      transform: scale(5%);
    }
  }
  @keyframes chain {
    0%{
      right: 75px;
    }
    30% {
      right: 30px;
    }
    30% {
      right: 30px;
    }
    100%{
      right: 75px;
    }
  }
  
  .pending {
    background: #333;
    height: 200px;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    gap: 30px;
    border-radius: 3px;
  }
  
  
    `
function Pending ({show, setPending}) {
    if(!show) {
        return;
    }
    return <Component>
        <div className={'indicator'}>
            <div className="pending">
                <div className="loader">
                    <div className="block"></div>
                    <div className="block"></div>
                    <div className="block"></div>
                    <div className="block"></div>
                    <div className="block"></div>
                    <div className="bar"></div>
                </div>
                Pending...
            </div>
        </div>
    </Component>
}

export default Pending;