import {useEffect, useRef} from "react";


function ComplexCity({tokenId, hash}) {
    const frame = useRef();
    useEffect(() => {
        console.log(frame.current)
    }, [frame]);
    return(
            <iframe
                ref={ frame }
                style={{ overflow: 'hidden', border: "none", height: '100%', width: 'calc(100%)' }}
                src={ './artblock.htm?tokenId='+tokenId+'&hash='+hash }
            />
    )
}

export default ComplexCity;
        