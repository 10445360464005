import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Bundle from './Bundle';
import Success from "./Success";
import reportWebVitals from './reportWebVitals';
import {createHashRouter, RouterProvider} from "react-router-dom";
import SuccessBundle from "./SuccessBundle";
import John from "./John";

const GOERLI = 'goerli';
const ETH = 'ethereum';

const contracts = {
    bundle: { contract_address: '0xfE18667EfC1C5713f380951aD121e62e86277a3C', currency: ETH, price: '1.2' },
    bundle_test: { contract_address: '0xDa0DD38C9C7736771FFe0E2871e1208a57e6C819', currency: GOERLI, price: '0.02' },
    john: { contract_address: '0x13a72E919284a13358BF4698ACC9695Fe7342a49', nfts: '0x2da79f5e1a80ec648ffcec20a6d7e93df98f5d9e', nft_abi: require('./abis/goerli-test.nfts.abi.json'), abi: require('./abis/goerli-test.abi.json'), currency: GOERLI, price: '0.02' },
    john_test: { contract_address: '0x13a72E919284a13358BF4698ACC9695Fe7342a49',nfts: '0x2da79f5e1a80ec648ffcec20a6d7e93df98f5d9e', nft_abi: require('./abis/goerli-test.nfts.abi.json'), abi: require('./abis/goerli-test.abi.json'), currency: GOERLI, price: '0.02' },
}

const router = createHashRouter([
    {
        path: '/',
        element: <Bundle {...contracts.bundle} />,
    },
    {
        path: '/success_bundle',
        element: <SuccessBundle/>,
    },
    {
        path: '/success',
        element: <Success  {...contracts.bundle}/>,
    },
    {
        path: '/bundle_test',
        element: <Bundle {...contracts.bundle_test} />,
    },
    {
        path: '/john/:id',
        element: <John {...contracts.john}/>,
    },
    {
        path: '/john_test/:id',
        element: <John {...contracts.john_test}/>,
    },
])


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
        <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
