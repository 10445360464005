
import './App.css';
import AWLink from './components/AWLink'
import Title from './components/Title'
import Info from './components/Info'
import ScrollPane from './components/ScrollPane'
import DetailsPane from './components/DetailsPane'
import PurchaseBundle from './components/PurchaseBundle'
import Disclaimer from './components/Disclaimer'
import Right from './components/Right'
import Left from './components/Left'
import Container from './components/Container'
import React, {useState} from "react";
import {coinbaseWallet, localWallet, metamaskWallet, ThirdwebProvider, walletConnect} from "@thirdweb-dev/react";
import BundleGallery from "./components/BundleGallery";
import Pending from "./components/Pending";

const Bundle = ({contract_address, price, currency}) => {
        return (
            <ThirdwebProvider
                supportedWallets={[
                    localWallet(),
                    metamaskWallet(),
                    coinbaseWallet(),
                    walletConnect()]}
                activeChain={currency}
                clientId="6ccfb4087b2e762fcd08bb33bd3721b1">
                <BundleContent contract_address={contract_address} price={price} currency={currency}/>
            </ThirdwebProvider>
        )
}


const BundleContent = ({contract_address, price, currency}) => {

    const [pending, setPending] = useState(false);

  return (
      <>
      <Pending show={pending} setPending={setPending}/>
      <Container id="container">
         <Left>
             <AWLink></AWLink>
             <Title>John F. Simon Jr.</Title>
             <Title>ComplexCity Bundles</Title>
             <Title subtitle={true}>In Partnership with Art Blocks Engine</Title>
             <div className={'mobile_gallery'}>
                 <BundleGallery/>
             </div>
             <Info price={price} count={6}></Info>
             <ScrollPane>
                 <DetailsPane type={'Details'}>
                     Collect a complete set from John F. Simon Jr.’s <i>ComplexCity</i> (2000/ 2023). This pre-sale opportunity
                     ensures your access to all six templates from the collection.
                     <br/>
                     <br/>
                     Please note: collectors will be airdropped their artworks on
                     November 9, 2023.
                 </DetailsPane>
                 <DetailsPane type={'Description'}>
                     Updated from his seminal software artwork by the same name, John F. Simon Jr.’s <i>ComplexCity</i>’s code
                     explores contemporary urbanism through animated visual outputs containing abstracted elevators,
                     clogged intersections, a city skyline in constant flux and other interactive and dynamic forms that
                     have been collaged together in varying compositions to create 512 unique artworks.
                     <br/>
                     Similar to his earlier physical pieces, these tokens are not videos but software that is constantly changing —
                     real-time animations that create endless combinations in continuous motion.
                 </DetailsPane>
             </ScrollPane>
             <div style={{marginTop: 30}}></div>
             <PurchaseBundle {...{contract_address, currency, setPending}}></PurchaseBundle>
         </Left>
          <Right>
             <BundleGallery/>
             <Disclaimer>
                 *Artwork previews representative of the six different templates used throughout the collection.
             </Disclaimer>
         </Right>
      </Container>
    </>
  );
}

export default Bundle;
