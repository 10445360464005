import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {createSearchParams, useNavigate, useParams} from "react-router-dom";
import { ConnectButton } from '@rainbow-me/rainbowkit';
import {useAccount, useContractWrite, usePublicClient, useWaitForTransaction} from "wagmi";
import {readContract} from "viem/actions";

const contracts = {
    contract_address: '0x13a72E919284a13358BF4698ACC9695Fe7342a49',
    nfts: '0x2da79f5e1a80ec648ffcec20a6d7e93df98f5d9e',
    nft_abi: require('../abis/goerli-test.nfts.abi.json'),
    abi: require('../abis/goerli-test.abi.json'),
    currency: 'GOERLI',
    price: '0.02'
}

const Component = styled.div`
  .quantity_section {
    display: flex;
    justify-content: space-between;
    .quantity_select {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .count {
      margin-right: 10px;
    }
    .buttons {
      display: flex;
      flex-direction: column;
    }
    button {
      background: transparent;
      border: none;
    }
  }
  .pay_button {
    width: 100%;
    button {
      cursor: pointer;
      padding: 10px;
      border: none;
      border-radius: 7px;
      font-size: 0.7em;
      align-items: center;
      justify-content: center;
      display: flex;
      width: 100%;
      background: black;
      color: #EEE;
    }
    svg {
      margin-left: 10px;
    }
  }
`


function Purchase({setPending, web3x, show_count=true}) {

    const navigate = useNavigate();
    const [count, setCount] = useState(1);
    const {id} = useParams();
    const { address, isConnected } = useAccount()
    const [transaction, setTransaction] = useState(null)
    const [status, setStatus] = useState(null);

    const {write} = useContractWrite({
        address: contracts.contract_address,
        abi: contracts.abi,
        functionName: 'purchase',
        value: web3x.utils.toWei('0.02', 'ether'),
        account: address,
        args: [id],
        onSuccess: (data) => {
            console.log('SUCCESS!', data);
            setTransaction(data.hash)
        },
        onSettled: () => {
        },
        onError: (e) => {
            setPending(false);
            setStatus(e.details)
        }
    })
    const {data} = useWaitForTransaction({
        hash: transaction,
    });

    const client = usePublicClient();

    useEffect(() => {
        if(!data) {
            return;
        }
        const complete_purchase = async (_data) => {
            const token_id_hex = _data.logs[0].topics.slice(-1)[0]
            const token_id = parseInt(token_id_hex, 16);
            const hash = await readContract(client, {
                address: contracts.nfts,
                abi: contracts.nft_abi,
                functionName: 'tokenIdToHash',
                args: [token_id],
            })
            setPending(false);
            navigate({pathname: '/success', search: createSearchParams({ currency:'goerli', token_id, to: data.to, hash }).toString()});
        };
        complete_purchase(data);

    }, [data])

    const purchaseClicked = async () => {
        setStatus('');
        setPending(true);
        write();
    }

    return (
        <Component>
            {show_count && (
            <div className={'quantity_section'}>
                <div className={'quantity'}>Quantity</div>
                <div className={'quantity_select'}>
                    <div className={'count'}>{count}</div>
                    <div className={'buttons'}>
                        <button onClick={() => setCount(e => e+1)}>
                            <svg height="10" width="16">
                                <polygon points="8,0 0,10 15,10" style={{fill: '#8C8C8C'}} />
                            </svg>
                        </button>
                        <button onClick={() => setCount(e => Math.max(1, e-1))}>
                            <svg height="10" width="16">
                                <polygon points="8,10 0,0 15,0" style={{fill: count === 1 ? '#00000020' : '#8C8C8C'}} />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            )}
            {status}
            {isConnected ? (
                <div className={"pay_button"}>
                    <button onClick={() => purchaseClicked()}>
                        Pay with Crypto Wallet
                        <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.29514 0.5L5.18848 0.866667V11.4467L5.29514 11.5533L10.2018 8.64667L5.29514 0.5Z" fill="#8C8C8C"/>
                            <path d="M5.29517 0.5L0.381836 8.64667L5.29517 11.5533V6.41333V0.5Z" fill="#E1E1E1"/>
                            <path d="M5.29511 12.48L5.23511 12.5533V16.32L5.29511 16.5L10.2084 9.57996L5.29511 12.48Z" fill="#8C8C8C"/>
                            <path d="M5.29517 16.5V12.48L0.381836 9.57996L5.29517 16.5Z" fill="#E1E1E1"/>
                            <path d="M5.29517 11.5533L10.2018 8.64666L5.29517 6.41333V11.5533Z" fill="#393939"/>
                            <path d="M0.381836 8.64666L5.29517 11.5533V6.41333L0.381836 8.64666Z" fill="#838383"/>
                        </svg>
                    </button>
                </div>
            ) : (
                <div className={"pay_button"}>
                    <ConnectButton/>
                </div>
            )}

        </Component>
    )
}

export default Purchase;